import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Container = styled.input`
  border: 0;
  border: ${(props) => props.theme.boxBorder};
  background-color: ${(props) => props.theme.bgColor};
  border-radius: ${(props) => props.theme.borderRadius};
  height: 35px;
  font-size: 12px;
  padding: 0px 15px;
`;
const Input = ({ placeholder, required, value, onChange, type="text", className, autoComplete}) => (
  <Container
  className = {className}
    placeholder={placeholder}
    requited={required}
    value={value}
    onChange={onChange}
    type = {type}
    autoComplete ={autoComplete}
  />
);

Input.propTypes = {
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  text: PropTypes.string,
  autoComplete : PropTypes.string
};

export default Input;
