import React from "react";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "@apollo/react-hooks";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import RoundButton from "../Components/Buttons/RoundedButton";

import moment from "moment";
import "moment/locale/ko";
// import { Lecture } from "../Components/Icons";
const mySchdule = gql`
  {
    mySchdules {
      id
      start_date
      end_date
      totalPlayTime
      is_extended
      Lecture {
        id
        title
        end_date
        start_date
        Lesson {
          id
          title
          menu_title
          url
        }
      }
    }
  }
`;

export const CREATE_ACCOUNT = gql`
  mutation createAccount(
    $name: String!
    $email: String!
    $phoneLast4: String!
    $password: String!
  ) {
    createAccount(
      name: $name
      phoneLast4: $phoneLast4
      email: $email
      secret: $password
    )
  }
`;

const EXTEND_END_DATE = gql`
  mutation extendEndDate($id: String!) {
    extendEndDate(id: $id)
  }
`;

const Wrapper = styled.div`
  display: flex;
  padding: 20px;
  flex-flow: wrap;
`;

const Box = styled.div`
  ${(props) => props.theme.whiteBox}
  border-radius:4px;
  width: 100%;
  max-width: 350px;
`;

const Card = styled(Box)`
  text-align: center;
  padding: 0px 0px;
  min-height: 200px;
  margin: 4px;
  width: 350px;
  position: relative;
  line-height: 1.8em;
`;

const InactiveCard = styled(Card)`
  color: #aaa;
`;

const Title = styled.div`
  text-align: center;
  font-size: 20px;
  padding: 10px;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding: 8px;
  height: 100px;
`;

const DisableWrapper = styled(ContentWrapper)`
  background-color: rgba(11, 11, 11, 0.1);
  vertical-align: middle;
  color: #000;
  flex-direction: column;
`;

const ContentList = styled.ul`
  line-height: 1.8em;
  font-size: 14px;
`;
const Content = styled.li``;

const Duration = styled.div`
  border-bottom: ${(props) => props.theme.boxBorder};
  padding-bottom: 10px;
`;

const BoldText = styled.span`
  font-size: 20px;
  font-weight: 600;
  margin-top: 4px;
  margin-bottom: 12px;
`;

const Status = styled.div`
  position: absolute;
  left: 10px;
  top: 10px;
  background: #ddd;
  text-align: center;
  border-radius: 10px 10px 10px 10px;
  color: white;
  padding: 0px 10px;
  font-size: 10px;
`;
const RightBedge = styled(Status)`
  right: 10px;
  left: unset;
  background: unset;
  color: #999;
`;

const Completed = styled(Status)`
  background: #56ca4d;
`;

const GoingTo = styled(Status)`
  background: #111;
`;

// function pad(num) {
//   return ("0" + num).slice(-2);
// }

const hhmmss = (sec) => {
  var minutes = Math.floor(sec / 60);
  // sec = sec % 60;
  // var hours = Math.floor(minutes / 60);
  // minutes = minutes % 60;
  return `${minutes}분`;
};

const LessonStatus = ({ totalPlayTime }) => {
  if (totalPlayTime > 0) {
    return (
      <>
        <Completed>출석완료</Completed>
      </>
    );
  } else {
    return <GoingTo>미 시청</GoingTo>;
  }
};

const TotalPlayTime = ({ totalPlayTime }) => {
  const time = hhmmss(totalPlayTime);
  if (totalPlayTime > 0) {
    return (
      <>
        <RightBedge>재생시간 : {time}</RightBedge>
      </>
    );
  } else {
    return <> </>;
  }
};

const InacitiveButton = styled.div`
   {
    border: 0;
    border-radius: 14px;
    color: white;
    font-weight: 600;
    background-color: ${(props) => props.theme.daryGreyColor};
    text-align: center;
    padding: 4px 12px;
    font-size: 14px;
    text-align: center;
  }
`;

const LessonCard = ({ schdule }) => {
  const {
    id,
    start_date,
    end_date,
    totalPlayTime,
    is_extended,
    Lecture: { id: LecutreId, title, Lesson },
  } = schdule;

  const formatedEndDate = moment(end_date, "YYYYMMDDHHmmss").locale("ko");
  const formatedStartDate = moment(start_date, "YYYYMMDDHHmmss").locale("ko");
  const startDateStr = formatedStartDate.format("MMM Do");
  const endDateStr = formatedEndDate.format("MMM Do");
  const isAvailable = moment() < formatedEndDate ? true : false;
  const [extendEndDatemutation] = useMutation(EXTEND_END_DATE);

  const onClickExtendButton = (id) => {
    const { isLoading } = extendEndDatemutation({
      variables: { id: id },
    });
    if (!isLoading) {
      window.location.reload();
    }
  };

  return (
    <>
      {isAvailable && (
        <>
          <Card>
            <Title>{title}</Title>
            <LessonStatus totalPlayTime={totalPlayTime}></LessonStatus>
            <TotalPlayTime totalPlayTime={totalPlayTime}></TotalPlayTime>
            <Duration>
              시청 기간 : {startDateStr} ~ {endDateStr}
            </Duration>
            <ContentWrapper>
              <ContentList>
                {Lesson.map((lesson) => {
                  return (
                    <Content key={lesson.id}>
                      <Link
                        to={{
                          pathname: `/lecture/${LecutreId}/${lesson.id}/${end_date}`,
                        }}
                      >
                        {lesson.title}
                      </Link>
                    </Content>
                  );
                })}
              </ContentList>
            </ContentWrapper>
          </Card>
        </>
      )}
      {!isAvailable && (
        <>
          <InactiveCard isAvailable={true}>
            <Title>{title}</Title>
            <LessonStatus totalPlayTime={totalPlayTime}></LessonStatus>
            <TotalPlayTime totalPlayTime={totalPlayTime}></TotalPlayTime>
            <Duration>
              시청 기간 : {startDateStr} ~ {endDateStr}
            </Duration>
            <DisableWrapper>
              <BoldText>시청기간이 종료되었습니다.</BoldText>
              {is_extended ? (
                <InacitiveButton>더 이상 연장할 수 없습니다.</InacitiveButton>
              ) : (
                <RoundButton
                  onClick={() => onClickExtendButton(id)}
                  text={"24시간 연장 요청"}
                />
              )}
            </DisableWrapper>
          </InactiveCard>
        </>
      )}
    </>
  );
};

export default () => {
  const { data, loading } = useQuery(mySchdule);

  return (
    <Wrapper>
      <Helmet>
        <title></title>
      </Helmet>
      {loading && <span>loading</span>}
      {!loading &&
        data &&
        data.mySchdules &&
        data.mySchdules.map((schdule) => {
          return <LessonCard key={schdule.id} schdule={schdule} />;
        })}
    </Wrapper>
  );
};
