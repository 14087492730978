import ApolloClient from "apollo-boost";
import { defaults, resolvers } from "./LocalState";
import { getWithExpiry } from "../utils";
import { toast } from "react-toastify";

console.log();

export default new ApolloClient({
  uri:
    process.env.NODE_ENV === "development"
      ? "http://localhost:4000"
      : "http://49.247.130.199:4000",
  clientState: {
    defaults,
    resolvers,
  },
  request: (operation) => {
    operation.setContext({
      headers: {
        Authorization: `Bearer ${getWithExpiry("token")}`,
      },
    });
  },
  onError: ({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) => {
        if (message === "Unauthorized") {
          toast.error(
            "로그인 정보가 잘못되었습니다. 다시 로그인 해주세요. 3초 후 로그인 화면으로 이동합니다."
          );
          setTimeout(() => {
            localStorage.removeItem("token");
            localStorage.removeItem("r");
            window.location = "/";
          }, 3000);
        }
        return null;
      });
    }
    if (networkError) {
    }
  },
});
