import React from "react";
import styled from "styled-components";
import Input from "../../Components/Input";
import Button from "../../Components/Buttons/Button";
import {LargeLogo } from "../../Components/Icons";

const Wrapper = styled.div`
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Box = styled.div`
  ${(props) => props.theme.whiteBox}
  border-radius:0px;
  width: 100%;
  max-width: 350px;
`;

const StateChanger = styled(Box)`
  text-align: center;
  padding: 20px 0px;
`;

const Link = styled.span`
  color: ${(props) => props.theme.blueColor};
  cursor: pointer;
`;

const Form = styled(Box)`
  padding: 40px;
  padding-bottom: 30px;
  margin-bottom: 15px;
  form {
    width: 100%;
    input {
      width: 100%;
      &:not(:last-child) {
        margin-bottom: 7px;
      }
    }
    button {
      margin-top: 7px;
    }
  }
`;

const LogoTitle = styled.div`
  text-align:center;
  margin-bottom : 30px;
`

export default ({
  setAction,
  action,
  email,
  username,
  password,
  phoneLast4,
  onLogin,
  onResetPassword,
  onChangePassword
}) => (
  <Wrapper>
    <Form>
      {action === "logIn" && (
        <>
        <LogoTitle><LargeLogo /></LogoTitle>
        
        
        <form onSubmit={onLogin}>
          <Input placeholder={"e-mail"} {...email} />
          <Input
            placeholder={"Password"}
            {...password}
            type={"password"}
            autoComplete="current-password"
          />
          <Button text={"로그인"} />
        </form>
        </>
      )}
      {/*       
      {action === "signUp" && (
        <form onSubmit={onSignIn}>
          <Input placeholder={"성명"} required={true} {...username} />
          <Input
            placeholder={"핸드폰 번호 뒤 4자리"}
            required={true}
            {...phoneLast4}
          />
          <Input
            placeholder={"e-mail"}
            required={true}
            {...email}
            autocomplete="username"
          />
          <Input
            placeholder={"비밀번호"}
            required={true}
            {...password}
            type={"password"}
            autocomplete="new-password"
          />
          <Button text={"가입하기"} />
        </form>
      )} */}
      {action === "resetPassword" && (
        <form onSubmit={onResetPassword}>
          <Input
            placeholder={"e-mail"}
            required={true}
            {...email}
            autoComplete="username"
          />
          <Input placeholder={"성명"} required={true} {...username} />
          <Input
            placeholder={"핸드폰 번호 뒤 4자리"}
            required={true}
            {...phoneLast4}
          />
          <Button text={"비밀번호 초기화"} />
        </form>
      )}
      {action === "changePassword" && (
        <form onSubmit={onChangePassword}>
         <Input
            placeholder={"비밀번호"}
            required={true}
            {...password}
            type={"password"}
            autoComplete="new-password"
          />
          <Button text={"비밀번호 초기화"} />
        </form>
      )}
    </Form>

    <StateChanger>
      {action === "logIn" ? (
        <>
          비밀번호가 기억나지 않으신가요?{" "}
          <Link onClick={() => setAction("resetPassword")}>
            비밀번호 초기화
          </Link>
        </>
      ) : (
        <>
          비밀번호가 기억나셨나요?{" "}
          <Link onClick={() => setAction("logIn")}>로그인</Link>{" "}
        </>
      )}
    </StateChanger>
  </Wrapper>
);
