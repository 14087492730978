import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Container = styled.button`
  background-color:white;
  text-align : center;
  font-size : 10px;
  border : 0;
`;

const MenuButton = ({ Icon, text ,onClick}) => <Container onClick = {onClick}><Icon/><br/>{text}</Container>;

MenuButton.propTypes = {
  text: PropTypes.string.isRequired,
};

export default MenuButton;
