import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Container = styled.button`
  border : 0;
  border-radius : 14px;
  color : white;
  font-weight : 600;
  background-color:${props=> props.theme.blueColor};
  text-align : center;
  padding : 4px 12px;
  font-size : 14px;

  &:hover{
    background-color:${props=> props.theme.darkBlueColor};
  }

  $:active{
    background-color: #eee;
  }
`;

const RoundedButton = ({ text, onClick, active}) => <Container onClick={onClick} active>{text}</Container>;

RoundedButton.propTypes = {
  text: PropTypes.string.isRequired,
};

export default RoundedButton;
