import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
//import Input from "./Input";
// import useInput from "../Hooks/useInput";
import { Lecture, Logo, Logout } from "./Icons";
import MenuButton from "./Buttons/MenuButton";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { toast } from "react-toastify";
const Header = styled.header`
  width: 100%;
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  border-bottom: ${(props) => props.theme.boxBorder};
  border-radius: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 25px;
  z-index: 99;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  max-width: ${(props) => props.theme.maxWidth};
  display: flex;
  justify-content: center;
`;

const HeaderColumn = styled.div`
  width: 33%;
  text-align: center;
  &:first-child {
    margin-right: auto;
    text-align: left;
  }
  &:last-child {
    margin-left: auto;
    text-align: right;
    color: #123;
  }
`;

// const SearchInput = styled(Input)`
//   background-color: ${(props) => props.theme.bgColor};
//   padding: 5px;
//   font-size: 14px;
//   border-radius: 3px;
//   height: auto;
//   text-align: center;
//   width: 70%;
//   &::placeholder {
//     opacity: 0.8;
//     font-weight: 200;
//   }
// `;

const HeaderLink = styled(Link)`
  &:not(:last-child) {
    margin-right: 30px;
  }
`;

const LOCAL_LOG_OUT = gql`
  mutation logUserOut {
    logUserOut @client
  }
`;

const GET_NOTICE = gql`
  query {
    notice {
      message
    }
  }
`;

export default ({ role }) => {
  const [localLogOutMutation] = useMutation(LOCAL_LOG_OUT);

  const { data, loading } = useQuery(GET_NOTICE);
  if (!loading && data && data.notice && data.notice.message) {
    toast.info(data.notice.message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      });
    
  }
  return (
    <Header>
      <HeaderWrapper>
        <HeaderColumn>
          <Link to="/">
            <Logo />
          </Link>
        </HeaderColumn>
        {role === "REQ" && (
          <>
            <span>
              {" "}
              현재 담당자 승인 대기 상태입니다. <br />
              가입 후 24시간내로 처리됩니다.
            </span>
          </>
        )}
        <HeaderColumn></HeaderColumn>
        <HeaderColumn>
          {role === "STU" && (
            <>
              <HeaderLink to="/lecture">
                <MenuButton Icon={Lecture} text={"강의 목록"}></MenuButton>
              </HeaderLink>
              {/* <HeaderLink to="/attendance">
                <MenuButton Icon={AttendList} text={"출석"}></MenuButton>
              </HeaderLink> */}
            </>
          )}
          {role === "TUT" && (
            <>
              <HeaderLink to="/lecture">
                <MenuButton Icon={Lecture} text={"강의 목록"}></MenuButton>
              </HeaderLink>
              {/* <HeaderLink to="/attendance">
                <MenuButton Icon={AttendList} text={"출석"}></MenuButton>
              </HeaderLink> */}
            </>
          )}
          {/* <HeaderLink to="/username">
            <MenuButton Icon={User} text={"프로필"}></MenuButton>
          </HeaderLink> */}
          <HeaderLink to="/logout">
            <MenuButton
              onClick={() => localLogOutMutation()}
              Icon={Logout}
              text={"로그아웃"}
            ></MenuButton>
          </HeaderLink>
        </HeaderColumn>
      </HeaderWrapper>
    </Header>
  );
};
