import React, { useState  } from "react";
import useInput from "../../Hooks/useInput";

import { useMutation } from "@apollo/react-hooks";
import AuthPresenter from "./AuthPresenter";
import {
  LOG_IN,
  CREATE_ACCOUNT,
  LOCAL_LOG_IN,
  RESET_PASSWORD,
} from "./AuthQueries";
import { toast } from "react-toastify";

export default () => {
  const [action, setAction] = useState("logIn");
  const email = useInput("");
  const username = useInput("");
  const password = useInput("");
  const phoneLast4 = useInput("");
  // const isMountedRef = useIsMountedRef();
  const [confirmSecretMutation] = useMutation(LOG_IN, {
    variables: { email: email.value, password: password.value },
  });

  const [createAccountMutation] = useMutation(CREATE_ACCOUNT, {
    variables: {
      name: username.value,
      phoneLast4: phoneLast4.value,
      email: email.value,
      password: password.value,
    },
  });

  const [localLogInMutation] = useMutation(LOCAL_LOG_IN)
  

  const [resetPasswordMutation] = useMutation(RESET_PASSWORD, {
    variables: {
      email: email.value,
      phoneLast4: phoneLast4.value,
      name: username.value,
    },
  });


  const onLogin = async (e) => {
    e.preventDefault();

    if (email.value === "") {
      toast.error("이메일을 입력해주세요.");
      return;
    }
    if (password.value === "") {
      toast.error("비밀번호를 입력해주세요.");
      return;
    }

    try {
      const result = await confirmSecretMutation();

      const {
        data: { confirmSecret: {token,role} },
      } = result;
      if (token !== "" && token !== undefined) {
          localLogInMutation({variables: { token, role }})
          
          
      } else {
        toast.error("로그인 정보가 잘못 되었습니다.");
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  const onSignIn = async (e) => {
    e.preventDefault();
    if (email.value === "") {
      toast.error("이메일을 입력해주세요.");
    } else if (password.value === "") {
      toast.error("비밀번호를 입력해주세요.");
    } else if (phoneLast4.value === "") {
      toast.error("핸드폰번호 뒤 4자리를 입력해주세요.");
    } else if (username.value === "") {
      toast.error("성함을 정확히 입력해주세요.");
    }

    
    if (email.value !== "" && password.value !== "") {
      try {
        const result = await createAccountMutation();
        const {
          data: { createAccount },
        } = result;
        if (!createAccount) {
          toast.error("error");
        } else {
          toast.success("createAccount");
          setTimeout(() => {
            setAction("logIn");
          }, 1000);

        }
      } catch (e) {
        toast.error(e.message);
      }
    }
  };

  const onResetPassword = async (e) => {
    e.preventDefault();

    if (email.value === "") {
      toast.error("이메일을 입력해주세요.");
    } else if (phoneLast4.value === "") {
      toast.error("핸드폰번호 뒤 4자리를 입력해주세요.");
    } else if (username.value === "") {
      toast.error("성함을 정확히 입력해주세요.");
    }

    try {
      const result = await resetPasswordMutation();
      const {
        data: { resetPassword },
      } = result;
      if (!resetPassword) {
        toast.error("입력된 정보가 잘못 되었습니다.");
      } else {
        toast.success("초기화된 비밀번호가 메일로 전송되었습니다.");
        setTimeout(() => {
          setAction("logIn");
        }, 5000);

      }
    } catch (e) {
      toast.error(e.message);
    }
  };


  return (
    <AuthPresenter
      setAction={setAction}
      action={action}
      email={email}
      username={username}
      password={password}
      phoneLast4={phoneLast4}
      onLogin={onLogin}
      onSignIn={onSignIn}
      onResetPassword={onResetPassword}
    />
  );
};
