import React from "react";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { Redirect } from "react-router-dom";

import Loader from "../Components/Loader";
const GET_ME = gql`
  {
    me {
      name
      shouldChange
    }
  }
`;

export default () => {
  const { data, loading } = useQuery(GET_ME);
  return (
    <>
      {loading && <Loader />}
      {!loading && data && data.me && data.me.shouldChange && (
        <>
          <Redirect to={{ pathname: "/init" }} />
        </>
      )}
      {!loading && data && data.me && !data.me.shouldChange && (
        <>
          <Redirect to={{ pathname: "/lecture" }} />
        </>
      )}
    </>
  );
};
