import React from "react";
import styled from "styled-components";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { NavLink } from "react-router-dom";
const GET_LECTURE = gql`
  query getLecture($id: String!) {
    getLecture(id: $id) {
      id
      title
      Lesson {
        id
        title
        url
        menu_title
      }
    }
  }
`;

const SubHeader = styled.div`
  width: 100%;
  display: flex;
  height: 30px;
  border: 1;
  padding: 10px;
  top: 0;
  left: 0;
  border-bottom: ${(props) => props.theme.boxBorder};
  border-radius: 0px;
  align-items: left;
`;

export default ({ lectureId }) => {
  const { data, loading } = useQuery(GET_LECTURE, {
    variables: { id: lectureId },
  });

  if (!loading && data && data.getLecture) {
    const {
      getLecture: { title, Lesson },
    } = data;

    const linkString = Lesson.map((lesson, index) => (
      <div key={`${lectureId}_${lesson.id}`}>
        {index !== 0 ? <span>&nbsp;&gt;&nbsp;</span> : null}
        <NavLink
          to={`/lecture/${lectureId}/${lesson.id}`}
          activeStyle={{
            fontWeight: "bold",
            color: "blue",
          }}
          isActive={(match, _) => {
            if (!match) {
              return false;
            }
            if (match.url === `/lecture/${lectureId}/${lesson.id}`) {
              return true;
            }
          }}
        >
          {lesson.menu_title}
        </NavLink>
      </div>
    ));

    return (
      <SubHeader>
        {title}&nbsp;:&nbsp;{linkString}
      </SubHeader>
    );
  } else {
    return <SubHeader>Loading...</SubHeader>;
  }
};
