import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js';
import React from "react";
import {Reset} from "styled-reset";

import ReactDOM from "react-dom";
import App from "./Components/App";
import { ApolloProvider } from "@apollo/react-hooks";
import Client from "./Apollo/Client"

ReactDOM.render(
  <ApolloProvider client={Client}>
    <Reset />

    <App />
  </ApolloProvider>,
  document.getElementById("root")
);
