
import React from "react";
import { gql } from "apollo-boost";
import GlobalStyles from "../Styles/GlobalStyles";
import styled, { ThemeProvider } from "styled-components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { HashRouter as Router } from "react-router-dom";
import Theme from "../Styles/Theme";
import AppRouter from "./Routes";
import { useQuery } from "@apollo/react-hooks";
// import Footer from "./Footer";
import {Reset} from "styled-reset"

const QUERY = gql`
  {
    isLoggedIn @client
    role @client
  }
`;

const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
`;

export default () => {
  const {
    data: { isLoggedIn, role },
  } = useQuery(QUERY);

  return (
    <ThemeProvider theme={Theme}>
      <>
        <GlobalStyles />
        <Router>
          <>
            <Wrapper>
              <AppRouter isLoggedIn={isLoggedIn} role={role} />
            </Wrapper>
          </>
        </Router>
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </>
    </ThemeProvider>
  );
};
