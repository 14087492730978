import React, { useEffect } from "react";
import fluidPlayer from "fluid-player";
import "fluid-player/src/css/fluidplayer.css";
import styled from "styled-components";
import { TaskTimer } from "tasktimer";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

const SAVE_LAST_POSITION = gql`
  mutation updateLessonStatus(
    $lesson_id: String!
    $status: String
    $last_position: Float
    $total_play_time: Int
  ) {
    updateLessonStatus(
      lesson_id: $lesson_id
      status: $status
      last_position: $last_position
      total_play_time: $total_play_time
    )
  }
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 0px 3em;
`;
const Video = styled.video`
  height: unset !important;
  border-radius: 12px;
`;

export const VideoPlayer = ({ videoSrc, lessonId, LessonStatus }) => {
  var player = null;
  var currentTime = 0;
  var timeStarted = -1;
  var timePlayed = 0;

  if (
    LessonStatus !== "" &&
    LessonStatus.total_play_time !== undefined &&
    LessonStatus.total_play_time !== null
  ) {
    timePlayed = LessonStatus.total_play_time;
  }

  const videoStartedPlaying = () => {
    timeStarted = new Date().getTime() / 1000;
  };

  const videoStoppedPlaying = () => {
    if (timeStarted > 0) {
      var playedFor = new Date().getTime() / 1000 - timeStarted;
      timeStarted = -1;
      timePlayed += playedFor;
    }
  };

  const [updateLessonStatusMutation] = useMutation(SAVE_LAST_POSITION);

  useEffect(() => {
    const videoJSOptions = {
      layoutControls: {
        fillToContainer: true,
        primaryColor: "#F9B234",
        playbackRateEnabled: true,
        autoPlay: false,
      },
    };

    const timer = new TaskTimer(30000);
    timer.on("tick", () => {
      videoStoppedPlaying();
      updateLessonStatusMutation({
        variables: {
          lesson_id: lessonId,
          status: "WATCHING",
          last_position: currentTime,
          total_play_time: Math.round(timePlayed),
        },
      }).then(videoStartedPlaying());
    });

    player = fluidPlayer("vls-video", videoJSOptions);

    if (
      LessonStatus.last_position &&
      LessonStatus.last_position !== null &&
      LessonStatus.last_position !== undefined
    ) {
      player.skipTo(LessonStatus.last_position);
    } else {
      player.skipTo(0);
    }

    player.on("play", function () {
      timer.start();
      videoStartedPlaying();
    });

    player.on("playing", function () {
      timer.start();
      videoStartedPlaying();
    });

    player.on("pause", () => {
      timer.stop();
      videoStoppedPlaying();
      updateLessonStatusMutation({
        variables: {
          lesson_id: lessonId,
          status: "WATCHING",
          last_position: currentTime,
          total_play_time: Math.round(timePlayed),
        },
      });
    });
    player.on("ended", () => {
      timer.stop();
      videoStoppedPlaying();
      updateLessonStatusMutation({
        variables: {
          lesson_id: lessonId,
          status: "DONE",
          last_position: currentTime,
          total_play_time: Math.round(timePlayed),
        },
      });
    });

    player.on("timeupdate", (time) => {
      currentTime = time;
    });

    return function cleanup() {
      player = null;
      window.location.reload();
    };
  }, [videoSrc]);

  return (
    <Wrapper>
      <Video id="vls-video">
        <source
          src={videoSrc}
          data-fluid-hd
          type="application/x-mpegURL"
        ></source>
      </Video>
    </Wrapper>
  );
};
