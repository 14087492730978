import PropTypes from "prop-types";
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Auth from "../Routes/Auth";
import Dashboard from "../Routes/Dashboard";
import ChangePassword from "../Routes/ChangePassword";
import Lecture from "../Routes/Lecture";
import Lesson from "../Routes/Lesson";
import Profile from "../Routes/Profile";
import Attendance from "../Routes/Attendance";
import Header from "./Header";


const LoggedInRoutes = () =>{
  return (
  
    <>
      <Header role="STU"/>
      <Switch>
        <Route exact path="/" component={Dashboard} />
        <Route path="/init" component={ChangePassword} />
        <Route path="/lecture/:lectureId/:lessonId/:endDate" component={Lesson} />
        <Route path="/lecture/:lectureId/:lessonId/" component={Lesson} />
        <Route exact path="/lecture" component={Lecture} />
        <Route path="/profile" component={Profile} />
        <Route path="/attendance" component={Attendance} />
        <Redirect from="*" to="/" />
      </Switch>
    </>
  );
}
// const LoggedInRoutes = ({ role }) => {
//   if (role === "REQ") {
//     return (
//       <>
//         <Header role={role} />
//         <RequestUser />
//       </>
//     );
//   } else if (role === "STU") {
//     return (
//       <>
//         <Header role={role} />
//         <Switch>
//           <Route exact path="/" component={Dashboard} />
//           <Route path="/init" component={ChangePassword} />
//           <Route path="/lecture/:lectureId/:lessonId" component={Lesson} />
//           <Route exact path="/lecture" component={Lecture} />
//           <Route path="/profile" component={Profile} />
//           <Route path="/attendance" component={Attendance} />
//           <Redirect from="*" to="/" />
//         </Switch>
//       </>
//     );
//   } else if (role === "TUT") {
//     return (
//       <>
//         <Header role={role} />
//         <Switch>
//           <Route exact path="/" component={Dashboard} />
//           <Route path="/init" component={ChangePassword} />
//           <Route path="/lecture/:lectureId/:lessonId" component={Lesson} />
//           <Route path="/lecture" component={Lecture} />
//           <Route path="/profile" component={Profile} />
//           <Route path="/attendance" component={Attendance} />
//           <Redirect from="*" to="/" />
//         </Switch>
//       </>
//     );
//   }
//   return <div> 잘못된 접근 입니다.</div>;
// };

const LoggedOutRoutes = () => (
  <Switch>
    <Route exact path="/" component={Auth} />
    <Redirect from="*" to="/" />;
  </Switch>
);


const AppRouter = ({ isLoggedIn, role }) => {
  
return isLoggedIn ? <LoggedInRoutes/> : <LoggedOutRoutes />;
    
};

AppRouter.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
};

export default AppRouter;
