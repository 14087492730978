import React from "react";
import styled from "styled-components";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { VideoPlayer } from "./../Hooks/useVideo";
import LessonSubHeader from "./../Components/LessonSubHeader";
import Loader from "./../Components/Loader";
import moment from "moment";

const withRouter = require("react-router-dom").withRouter;

const GET_LESSON = gql`
  query getLesson($id: String!) {
    getLesson(id: $id) {
      Lesson {
        id
        title
        url
        menu_title
      }
      LessonStatus {
        status
        last_position
        total_play_time
      }
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  max-width: 1600px;
  flex-direction: column;
`;

const Title = styled.div`
  text-align: center;
  padding: 10px;
  font-size: 24px;
`;

export default withRouter(
  ({location,
    match: {
      params: { lectureId, lessonId, endDate },
    },
  }) => {
    const { data, loading } = useQuery(GET_LESSON, {
      variables: { id: lessonId},
    });
    
    const formatedEndDate = moment(endDate, "YYYYMMDDHHmmss").locale("ko");
    
    const isAvailable = true 
    //moment() < formatedEndDate ? true : false;

    if (loading || !isAvailable) {
      return <Loader />;
    } else if (!loading && data && isAvailable) {
      const {
        getLesson: {
          Lesson: { title, url },
          LessonStatus,
        },
      } = data;

      return (
        <Wrapper>
          <LessonSubHeader lectureId={lectureId} />
          <Content>
            <Title>{title}</Title>

            <VideoPlayer
              videoSrc={url}
              lessonId={lessonId}
              LessonStatus={LessonStatus || ""}
            ></VideoPlayer>
          </Content>
        </Wrapper>
      );
    }
  }
);
