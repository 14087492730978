const BOX_BORDER = "1px solid #e6e6e6";
const BORDER_RADIUS = "4px";

export default {
  bgColor: "#fafafa",
  blackColor: "#262626",
  daryGreyColor: "#999",
  lightGreyColor: "c7c7c7",
  redColor: "ed4956",
  blueColor: "#3897fa",
  darkBlueColor: "#003569",
  boxBorder: `${BOX_BORDER}`,
  borderRadius: `${BORDER_RADIUS}`,
  borderRadiusBig: `16px`,
  whiteBox: `border-radius : ${BORDER_RADIUS};
                 border : ${BOX_BORDER};
                 background-color:white;
                `,
};
