import { createGlobalStyle } from "styled-components";


export default createGlobalStyle`
    @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;700;900&display=swap');
    * {
        box-sizing:border-box;
    }
    body {
        background-color:#fafafa;
        color:#262626;
        font-size : 14px;
        font-family:-apple-system, BlinkMacSystemFont, 'Noto Sans KR', sans-serif;
        padding-top: 65px;
    }
    a {
        color:"#3897fa";
        text-decoration : none;
    }
    input:focus{
        outline:none;
    }
`;
