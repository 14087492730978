import { setWithExpiry, getWithExpiry } from "../utils";
import ms from "ms";
export const defaults = {
  isLoggedIn: Boolean(getWithExpiry("token") || false),
  role: String(getWithExpiry("r") || "INIT"),
};

export const resolvers = {
  Mutation: {
    logUserIn: (_, { token, role }, { cache }) => {
      cache.writeData({
        data: {
          isLoggedIn: true,
          role: role,
        },
      });
      setWithExpiry("token", token, ms("7 days"));
      setWithExpiry("r", role, ms("7 days"));

      return null;
    },

    logUserOut: (_, __, { cache }) => {
      localStorage.removeItem("token");
      localStorage.removeItem("r");

      window.location = "/";
      return null;
    },
  },
};
