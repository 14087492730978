import { gql } from "apollo-boost";

export const LOG_IN = gql`
  mutation confirmSecret($email: String!, $password: String!) {
    confirmSecret(email: $email, secret: $password){
      token
      role
      shouldChange
    }
  }
`;

export const CREATE_ACCOUNT = gql`
  mutation createAccount(
    $name: String!
    $email: String!
    $phoneLast4: String!
    $password: String!
  ) {
    createAccount(
      name: $name
      phoneLast4: $phoneLast4
      email: $email
      secret: $password
    )
  }
`;

export const LOCAL_LOG_IN = gql`
  mutation logUserIn($token: String!, $role: String!) {
    logUserIn(token: $token, role:$role) @client
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword(
    $email: String!
    $name: String!
    $phoneLast4: String!
  ) {
    resetPassword(email: $email, phoneLast4: $phoneLast4, name: $name)
  }
`;
