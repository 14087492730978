import React from "react";
import styled from "styled-components";
import Input from "../Components/Input";
import Button from "../Components/Buttons/Button";
import { gql } from "apollo-boost";
import useInput from "../Hooks/useInput";
import { useMutation } from "@apollo/react-hooks";
import { toast } from "react-toastify";


const CHANGE_PASSWORD = gql`
  mutation changePassword($new_secret: String!) {
    changePassword(new_secret: $new_secret)
  }
`;

const Wrapper = styled.div`
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Box = styled.div`
  ${(props) => props.theme.whiteBox}
  border-radius:0px;
  width: 100%;
  max-width: 350px;
`;


const Text = styled.span`
line-height : 1.6em;
`

const Form = styled(Box)`
  padding: 40px;
  padding-bottom: 30px;
  margin-bottom: 15px;
  form {
    width: 100%;
    input {
      width: 100%;
      &:not(:last-child) {
        margin-bottom: 7px;
      }
    }
    button {
      margin-top: 7px;
    }
  }
`;

export default () => {
  const password = useInput("");

  const [changePasswordMutation] = useMutation(CHANGE_PASSWORD, {
    variables: {
      new_secret: password.value,
    },
  });

  const onChangePassword = async (e) => {
    e.preventDefault();

    if (password.value === "") {
      toast.error("비밀번호를 입력해주세요.");
    }

    if (password.value !== "") {
      
      try {
        const {
          data: { changePassword },
        } = await changePasswordMutation();
        if (!changePassword) {
          toast.error("비밀번호가 변경되지 않았습니다. 교무실에 문의해주세요.");
        } else {
          toast.success("비밀번호가 변경되었습니다.");
          setTimeout(() => (window.location = "/"), 1000);
        }
      } catch (e) {
        toast.error(e.message);
      }
    }
  };

  return (
    <Wrapper>
        
      <Form>
        <form onSubmit={onChangePassword}>
            <Text>비밀번호가 초기화 되었습니다. <br /> 비밀번호로 변경해주십시오.</Text>
          <Input
            placeholder={"새로운 비밀번호"}
            required={true}
            {...password}
            type={"password"}
            autocomplete="new-password"
          />
          <Button text={"비밀번호 변경합니다."} />
        </form>
      </Form>
    </Wrapper>
  );
};
