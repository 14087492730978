import React from "react";
import Loader from "react-loader-spinner";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import styled from "styled-components";

const Wrapper = styled.div`
  text-align: center;
  position: fixed;
  width : 100%;
  top : 100px;
`;
const Text = styled.span`
        font-size : 14px;
        color : #ddd;
        padding : 20px;
`

export default () => (
  <Wrapper>
    <Loader
      type="Grid"
      color="#f9b234"
      height={60}
      width={60}
      
    />
    <Text>Loading...</Text>
    
  </Wrapper>
);
